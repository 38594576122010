import React from "react";
import { useCustomer } from "../../../customHooks/useCustomer";
import CustomerMenu from "../CustomerMenu/CustomerMenu";
import DefaultMenu from "../DefaultMenu/DefaultMenu";

const MenuContainer = () => {
    const { isEnabled } = useCustomer();

    if (isEnabled) {
        return <CustomerMenu />;
    }

    return <DefaultMenu />;
}

export default MenuContainer;